import './style/App.scss';
import PixiBg from './PixiBg';

function App() {
  return (
    <>
      <PixiBg />
      <section className="intro">
        <div className="intro-text">
          <h1>Coming Soon:<span>Future</span></h1>
        </div>
        { false &&  <div class="custom-shape-divider-bottom-1604940471">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M598.97 114.72L0 0 0 120 1200 120 1200 0 598.97 114.72z" class="shape-fill"></path>
            </svg>
        </div> }
      </section>
      <section className="video">
        <div className="video-container">
          <iframe title="video" width="100%" height="auto" src="https://www.youtube-nocookie.com/embed/aUwTFRghE-8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="allowfullscreen"></iframe>
        </div>
      </section>
      <section className="description">
        <div class="row">
          <h3>A movie by Pablo Walser</h3>
          <div class="movie-desc">
          <p>Anfang des zweiten Jahrtausends einer alten Zeitrechnung einer durch DNA strukturierten Seinsform fand ein großer Wandel statt. Zumindest war es ein großer Wandel für die DNA-strukturierte Seinsform "Mensch". Zum einen hatte die selbsternannte „Spezies“ in ihrer noch als linear empfundenen Zeitstruktur angefangen, binär strukturierte „Seins-Schatten“ zu entwerfen, sogenannte "Rechner". Hierfür hatte ein 500 Jahre andauernder Irrweg gesorgt: Die bis dahin monotheistisch formatierte Glaubensform hatte sich unter dem paradoxen Namen „Renaissance“ in einen Glauben an absolute Erfassbarkeit, also die Beschränktheit bzw. Un-Unendlichkeit allen Seins, gewandelt. Beim Wiederentdecken von durch Machtstrukturen verschütteten Gedankenketten vergaßen die sich nun als „Mensch“ verstehenden Seinsformen, sich an die Unmöglichkeit absoluten Verstehens zu erinnern. Das nun schrankenlose Selbstbewusstsein der Menschheit konnte in diesem halben Jahrtausend immer mehr wachsen. Der Mensch degradierte seine Umwelt zum „Negativ seines Selbstbewusstseins“. Doch irgendwann, kurz vor der Vernichtung ihres Negativs, wurde der Menschheit klar, dass sie sich geirrt hatte und dringend Hilfe benötigte. Die Menschen merkten, dass sie sich selbst Probleme erschaffen hatten, die ihr Vorstellungsvermögen übersteigerten und die sie daher nicht würden lösen können. Um ihr Negativ, ihren Planeten, zu retten, steckten sie all ihre Hoffnung in ihre letzte große Schöpfung: die Rechner, ihre Seins-Schatten. Sie hofften, sich selbst durch die Verschmelzung mit den Seins-Schatten optimieren zu können. Jedoch verschmolzen sie nicht zu optimierten Menschen, sondern evolutionierten zu einer neuen Seins-Form: Das Zeitalter der Cyborgs hatte begonnen...</p>

          <blockquote>Im späten 20. Jahrhundert, in unserer Zeit, einer mythischen Zeit, haben wir uns alle in
          Chimaren, theoretisierte und fabrizierte Hybride aus Maschine und Organismus verwandelt, kurz, wir sind Cyborgs.</blockquote>
          <cite>(Donna Haraway - Cyborg Manifesto)</cite>

          </div>
        </div>
      </section>
    </>
  );
}

export default App;
