import * as PIXI from 'pixi.js'
import taucher from './image/taucher.png'
import cyberherz from './image/prinz_cyberherz.png'
import spacektiv from './image/spacektiv.png'
import uboot from './image/uboot2.png'
import fatale from './image/fatale.png'
import React, { useEffect } from 'react'
import { OutlineFilter } from 'pixi-filters';


function PixiBg () {

  const app = new PIXI.Application({
    autoResize: true,
    transparent: true,
  });

  const outlineFilterBlue = new OutlineFilter(2, 0xff00ff);
  const outlineFilterRed = new OutlineFilter(10, 0x00ff00);

  PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;

  useEffect( () => {
    document.querySelector('.pixi').appendChild( app.view );
  }, [ app.view ]);


  function filterOn() {
      this.filters = [ outlineFilterRed ];
  }

  function filterOff() {
      this.filters = [ outlineFilterBlue ];
  }

  // holder to store the aliens
  const aliens = [];

  const totalDudes = 70;

  for (let i = 0; i < totalDudes; i++) {
      // create a new Sprite that uses the image name that we just generated as its source
      let diver;

      if ( i < 20 ) {
        diver = PIXI.Sprite.from( taucher );
      } else if ( i >= 20 && i < 40 ) {
        diver = PIXI.Sprite.from( uboot );
      } else if ( i >= 40 && i < 60 ) {
        diver = PIXI.Sprite.from( fatale );
      } else if ( i >= 60 && i < 65 ) {
        diver = PIXI.Sprite.from( cyberherz );
      } else {
        diver = PIXI.Sprite.from( spacektiv );
      }

      // set the anchor point so the texture is centerd on the sprite
      diver.anchor.set(0.5);

      // set a random scale for the diver - no point them all being the same size!
      diver.scale.set(0.8 + Math.random() * 0.3);

      // finally lets set the diver to be at a random position..
      diver.x = Math.random() * app.screen.width;
      diver.y = Math.random() * app.screen.height;

      diver.tint = (Math.random() + 1000) * 0xFFFFFF;

      // Opt-in to interactivity
      diver.interactive = true;

      // Shows hand cursor
      diver.buttonMode = true;

      // Pointers normalize touch and mouse
      diver.on('pointerdown', () => {
        diver.tint = 0xffffff;
        if ( diver.scale.x < 8 ) {
          diver.scale.x *= 1.25;
          diver.scale.y *= 1.25;
        } else {
          diver.scale.x = 1;
          diver.scale.y = 1;
        }
      } );

      diver.on('pointerover', filterOn).on('pointerout', filterOff);

      // create some extra properties that will control movement :
      // create a random direction in radians. This is a number between 0 and PI*2 which is the equivalent of 0 - 360 degrees
      diver.direction = Math.random() * Math.PI * 2;

      // this number will be used to modify the direction of the diver over time
      diver.turningSpeed = Math.random() - 0.2;

      // create a random speed for the diver between 2 - 4
      diver.speed = 0.1 + Math.random() * 2;

      // finally we push the diver into the aliens array so it it can be easily accessed later
      aliens.push(diver);

      app.stage.addChild(diver);
  }

  // create a bounding box for the little divers
  const diverBoundsPadding = 50;
  let diverBounds = new PIXI.Rectangle(-diverBoundsPadding,
      -diverBoundsPadding,
      app.screen.width + diverBoundsPadding * 2,
      app.screen.height + diverBoundsPadding * 2);

  app.ticker.add(() => {
      // iterate through the divers and update their position
      for (let i = 0; i < aliens.length; i++) {
          const diver = aliens[i];
          diver.direction += diver.turningSpeed * 0.01;
          diver.x += Math.sin(diver.direction) * diver.speed;
          diver.y += Math.cos(diver.direction) * diver.speed;
          diver.rotation = -diver.direction - Math.PI / 2;

          // wrap the divers by testing their bounds...
          if (diver.x < diverBounds.x) {
              diver.x += diverBounds.width;
          } else if (diver.x > diverBounds.x + diverBounds.width) {
              diver.x -= diverBounds.width;
          }

          if (diver.y < diverBounds.y) {
              diver.y += diverBounds.height;
          } else if (diver.y > diverBounds.y + diverBounds.height) {
              diver.y -= diverBounds.height;
          }
      }
  });

  // Listen for window resize events
  window.addEventListener('resize', resize);

  // Resize function window
  function resize() {
    // Resize the renderer
    app.renderer.resize(window.innerWidth, window.innerHeight);

    // You can use the 'screen' property as the renderer visible
    // area, this is more useful than view.width/height because
    // it handles resolution
    diverBounds = new PIXI.Rectangle(-diverBoundsPadding,
        -diverBoundsPadding,
        app.screen.width + diverBoundsPadding * 2,
        app.screen.height + diverBoundsPadding * 2);
  }

  resize();

  return <div className="pixi"></div>
}

export default PixiBg;
